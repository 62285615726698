html {
  scroll-behavior: smooth;
}

.blueBackground {
  background-color:#AACFE1;
}

.navbar-nav > li{
  padding-left:10px;
  padding-right:10px;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgb(209, 236, 248);
}

#nav {
  background-color: rgb(116, 171, 199);
}

#profile {
  text-align: center;
  padding: 40px 0px 40px 0px;
  background: url(./assets/img/background.jpg) no-repeat;
  background-size: cover;
}

#profile_pic {
  height: 160px;
  width: 163px;
  margin-bottom: 20px;
  border-radius: 20px;
}

#profile .jumbotron {
  border-radius: 20px;
  padding: 40px;
  background-color: rgba(255,255,255,0.60);
  max-width: 800px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  #profile .jumbotron {
    padding: 20px;
  }
}

.spacing {
  height: 40px;
}

.site_section {
  text-align: center;
  padding: 0px 0px;
}

.site_section h1 {
  margin-bottom: 10px;
}

#welcome {
  max-width: 800px;
  font-size: 16px;
  margin: 0 auto;
}

.projects {
  padding-bottom: 50px;
}

* {box-sizing: border-box;}

.containerPortfolio {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute; 
  top: 0px; 
  left: 15px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8); /* Black see-through */
  width: 95%;
  height: auto;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 16px;
  padding: 15px;
  text-align: center;
}

.overlay h6 {
  font-size: 16px;
}

.overlay h5 {
  font-size: 20px;
}

.overlay a {
  font-size: 16px;
}

.overlay:hover {
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .overlay {
    font-size: 11px !important;
  }

  .overlay h6 {
    font-size: 11px !important;
  }

  .overlay h5 {
    font-size: 15px !important;
  }

  .overlay a {
    font-size: 11px !important;
  }
}

@media (max-width: 1200px) {
  .overlay {
    font-size: 12px;
  }

  .overlay h6 {
    font-size: 12px;
  }

  .overlay h5 {
    font-size: 15px;
  }

  .overlay a {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .overlay {
    font-size: 8px;
  }

  .overlay h6 {
    font-size: 8px;
  }

  .overlay h5 {
    font-size: 11px;
  }

  .overlay a {
    font-size: 8px;
  }
}

#portfolio img {
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}

.qual_title {
  background-color: rgb(116, 171, 199);
  border: 4px solid black !important;
}

.test {
  background-color:#DDECF3;
  margin: 1px;
}

.qual_elements {
  background-color: #DDECF3;
  border: 4px solid black !important;
  border-top: 0px solid black !important;
}

#csharp {
  height: 30px;
  width: 30px;
}

p {
  font-size: 1.1rem;
}

li {
  font-size: 1.3rem;
}

label {
  font-weight: bold;
}

.headings {
  border-bottom: 4px solid;
}

.contact {
  max-width: 500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.whiteBackground {
  background-color: white;
}

/* Lightbox Gallery CSS */
.photo-gallery {
  color: #313437;
  /* background-color: #fff; */
}

.photo-gallery p {
  color: #7d8285;
}

.photo-gallery h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width:767px) {
  .photo-gallery h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.photo-gallery .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 40px;
}

.photo-gallery .intro p {
  margin-bottom: 0;
}

.photo-gallery .photos {
  padding-bottom: 20px;
}

.photo-gallery .item {
  padding-bottom: 30px;
}

